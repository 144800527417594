import { IntroLayout, SEO, SignupForm } from 'components';
import React, { Fragment, useEffect } from 'react';
import { getFormState, handleFormSubmit } from '../utilities/formstate';

import { FORM_NAME } from '../utilities/introInformation';
import { Grid } from 'semantic-ui-react';
import { autoSigninExistingUser } from '../utilities/customers';
import { completedSignupPart1 } from '../events';
import { getUser } from '../utilities/auth';
import { navigate } from 'gatsby';
import { normalizeEmail } from '../../functions/users';
import { useResume } from '../utilities/resumeHook';
import { useTracking } from '../utilities/hooks';

const Checkmark = () => <i className="check icon"></i>;

const IndexPage = () => {
  const formState = getFormState(FORM_NAME);
  const resume = useResume();
  const user = getUser();

  useTracking('Index');

  useEffect(() => {
    if (user) {
      resume.run();
    }
  });

  const handleIntroFormSubmit = values => {
    (async () => {
      const email = normalizeEmail(values.email);
      const exists = await autoSigninExistingUser(email);

      if (exists) return;

      values.email = email;
      values.startAt = Date.now();
      handleFormSubmit(formState)(values, false);

      completedSignupPart1(values);

      navigate('/licenses');
    })();
  };

  const createAccountTemplate = () => (
    <IntroLayout text columns={2}>
      <SEO title="Home" />
      <h1 className="hero-text">
        IT’S TIME TO START RUNNING YOUR OPERATION WITH THE MOST RECOMMENDED SOFTWARE IN
        CANNABIS
      </h1>
      <p className="intro-text">
        Grow your operation next month with our risk-free 30 Day Trial, and see why we are
        the most recommended cannabis software on the market.
      </p>
      <Grid stackable columns={2}>
        <Grid.Column width={8}>
          <SignupForm
            initialValues={formState.values}
            working={formState.working || false}
            onSubmit={handleIntroFormSubmit}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="landing-copy-container">
            <h2>
              Your life is about to get way easier.
              <br />
              Start your free trial today.
            </h2>
            <ul>
              <li>
                <Checkmark />
                <p>No Credit Card Required</p>
              </li>
              <li>
                <Checkmark />
                <p>Trial is a 'Full Version' of GrowFlow - No missing features</p>
              </li>
              <li>
                <Checkmark />
                <p>We get your data moved over for you, and setup your account</p>
              </li>
              <li>
                <Checkmark />
                <p>In-App Chat support in case you get stuck</p>
              </li>
              <li>
                <Checkmark />
                <p>Includes One-on-One Training for you and your team</p>
              </li>
              <li>
                <Checkmark />
                <p>
                  30 Day Trial only starts once your account is fully setup and verified
                </p>
              </li>
              <li className="guarantee">
                <Checkmark />
                <p>
                  We want to make sure you get a true, full 30 days of use out of GrowFlow
                  to make sure it is the right fit for your operation
                </p>
              </li>
            </ul>
            <h2>
              Get back to easy
              <br />
              Get GrowFlow Today.
            </h2>
          </div>
        </Grid.Column>
      </Grid>
    </IntroLayout>
  );

  return user ? <Fragment /> : createAccountTemplate();
};

export default IndexPage;
